import { createContext, useContext } from 'react';
//
import { FiltersContextProps } from '../types';

// ----------------------------------------------------------------------

export const FiltersContext = createContext({} as FiltersContextProps);

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);

  if (!context) throw new Error('useFiltersContext must be use inside FiltersProvider');

  return context;
};
