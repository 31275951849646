// utils

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  TASKS: '/tasks',
  CALENDAR: '/calendar',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
  },
  calendar: {
    root: ROOTS.CALENDAR,
  },
  tasks: {
    root: ROOTS.TASKS,
    edit: (id: string) => `${ROOTS.TASKS}/${id}/edit`,
    new: `${ROOTS.TASKS}/new`,

    // user: {
    //   root: `${ROOTS.DASHBOARD}/user`,
    //   new: `${ROOTS.DASHBOARD}/user/new`,
    //   list: `${ROOTS.DASHBOARD}/user/list`,
    //   cards: `${ROOTS.DASHBOARD}/user/cards`,
    //   profile: `${ROOTS.DASHBOARD}/user/profile`,
    //   account: `${ROOTS.DASHBOARD}/user/account`,
    //   edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    //   demo: {
    //     edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
    //   },
    // },
  },
};
