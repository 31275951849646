import { ChangeEvent, ChangeEventHandler } from 'react';
import { Stack, Box, TextField, MenuItem } from '@mui/material';
import { useFiltersContext } from 'src/components/filters/context';

type Props = {
  themeMode: 'light' | 'dark';
};

export const GlobalFilters = ({ themeMode }: Props) => {
  const globalFilters = useFiltersContext();

  const handleChangeProvince: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    globalFilters.onUpdate('provinceId', e.target.value);
  };

  const renderView = (
    <TextField
      label="Province"
      fullWidth
      select
      value={globalFilters.provinceId}
      onChange={handleChangeProvince}
    >
      <MenuItem value="0">All</MenuItem>
      <MenuItem value="9">Barcelona</MenuItem>
      <MenuItem value="31">Málaga</MenuItem>
    </TextField>
  );

  return (
    <Box>
      <Stack spacing={2}>{renderView}</Stack>
    </Box>
  );
};
