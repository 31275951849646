import { useMemo, useCallback } from 'react';
import { isEqual } from 'lodash';
// hooks
import { useLocalStorage } from 'src/hooks/use-local-storage';
//
import { FiltersValueProps } from '../types';
import { FiltersContext } from './filters-context';

// ----------------------------------------------------------------------

type FiltersProviderProps = {
  children: React.ReactNode;
  defaultFilters: FiltersValueProps;
};

export function FiltersProvider({ children, defaultFilters }: FiltersProviderProps) {
  const [filters, setFilters] = useLocalStorage('global-filters', defaultFilters);

  const onUpdate = useCallback(
    (name: string, value: string | boolean) => {
      setFilters((prevState: FiltersValueProps) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setFilters]
  );

  const onReset = useCallback(() => {
    setFilters(defaultFilters);
  }, [setFilters, defaultFilters]);

  const canReset = !isEqual(filters, defaultFilters);

  const memoizedValue = useMemo(
    () => ({
      ...filters,
      onUpdate,
      onReset,
      canReset,
    }),
    [onUpdate, onReset, canReset, filters]
  );

  return <FiltersContext.Provider value={memoizedValue}>{children}</FiltersContext.Provider>;
}
