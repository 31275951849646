import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
// config
import { HOST_API } from 'src/config-global';
import MaintenancePackageJson from '../../package.json';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'Content-Type': 'application/json',
    'X-MaintenanceApp-Version': MaintenancePackageJson.version,
    'Content-Language': 'en',
  },
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (
  args: string | [string, AxiosRequestConfig],
  method: 'get' | 'post' = 'get'
) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  let res: AxiosResponse = {} as AxiosResponse;

  if (method === 'post') {
    if (config) {
      const { data, ...rest } = config;
      res = await axiosInstance[method](url, data, rest);
    } else {
      res = await axiosInstance[method](url);
    }
  } else {
    await axiosInstance[method](url, { ...config });
  }

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/api/auth/me',
    login: '/login',
    register: '/api/auth/register',
  },
  tasks: {
    list: '/ma/list',
    updateStatus: (id: string) => `ma/task/${id}`,
    updateTask: (id: string) => `ma/update/${id}`,
    updateMultipleTaskDueDate: '/ma/dueDate-task',
    updateMultipleTaskStatuses: '/ma/status-task',
    assignMultipleTasks: '/ma/assigned-task',
    deleteMultipleTasks: '/ma/delete-task',
  },
  calendar: {
    // events: '/ma/calendar',
    events: '/ma/calendar-task',
  },
  properties: {
    maintenanceNotes: (propertyId: string) => `/ma/maintenance-notes-property/${propertyId}`,
    maintenanceCompany: (propertyId: string) => `/ma/maintenance-company/${propertyId}`,
    handleDoor: (propertyId: string) => `ma/smartlock/${propertyId}`,
  },
};
